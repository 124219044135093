<template>
  <div style="overflow: hidden; position: relative;">
    <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="formInline">
<!--      <el-form-item prop="company">
        <el-select v-model="formInline.company" placeholder="请选择" size="medium">
          <el-option v-for="item in companies" :key="item.value" :label="item.lable" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item prop="xmdddocno">
        <el-input v-model="formInline.xmdddocno" placeholder="订单单号" clearable></el-input>
      </el-form-item>
      <el-form-item prop="xmddseq">
        <el-input v-model="formInline.xmddseq" placeholder="项次" clearable></el-input>
      </el-form-item>
      <el-form-item prop="xmddseq1">
        <el-input v-model="formInline.xmddseq1" placeholder="项序" clearable></el-input>
      </el-form-item>
      <el-form-item prop="xmdaua001">
        <el-input v-model="formInline.xmdaua001" placeholder="子项目令号（项目编号）" clearable></el-input>
      </el-form-item>
      <el-form-item prop="pjabl003">
        <el-input v-model="formInline.pjabl003" placeholder="项目名称" clearable></el-input>
      </el-form-item>
      <el-form-item prop="xmddua017">
        <el-input v-model="formInline.xmddua017" placeholder="产品信息单号" clearable></el-input>
      </el-form-item>
      <el-form-item style="margin-right: 0; white-space: nowrap;">
        <el-button type="primary" @click="onSearch('search')">查询</el-button>
        <el-button type="primary" plain @click="resetForm('formInline')">重置</el-button>
        <!-- <el-button type="info" plain @click="drawer = true">更多条件查询</el-button> -->
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%;" max-height="654" border v-loading="loading">
      <el-table-column fixed prop="xmdddocno" label="订单单号" width="180">
      </el-table-column>
      <el-table-column prop="xmddseq" label="项次" width="230">
      </el-table-column>
      <el-table-column prop="xmddseq1" label="项序" width="230">
      </el-table-column>
      <el-table-column prop="xmdcua004" label="供应类型" width="80">
      </el-table-column>
      <el-table-column prop="xmdcua018" label="公司细分产品名称" width="330">
      </el-table-column>
      <el-table-column prop="xmddud001" label="产地" width="150">
      </el-table-column>
      <el-table-column prop="plan_num" label="计划发货数量" width="120">
      </el-table-column>
      <el-table-column prop="plan_date" label="计划发货日期" width="130">
      </el-table-column>
      <el-table-column prop="created_time" label="填写时间" width="160">
      </el-table-column>
      <el-table-column prop="createder" label="填写人" width="100">
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="180">
      </el-table-column>
      <el-table-column prop="created" label="创建时间" width="160">
      </el-table-column>
      <el-table-column prop="updated" label="更新时间" width="160">
      </el-table-column>
      <el-table-column prop="statu" label="状态" width="100">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button @click.native.prevent="changeRow(scope.$index, tableData)" icon="el-icon-edit" type="text">
            修改
          </el-button>
          <!--          <el-divider direction="vertical"></el-divider>
          <template>
            <el-popconfirm title="确定删除此数据吗？" @confirm="deleteRow(scope.$index, tableData)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template> -->
        </template>
      </el-table-column>
    </el-table>
    <!--    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="rows"
          layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination> -->

    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="rows"
      layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <el-dialog title="修改详情页" :visible.sync="dialogVisible" width="60%" @close="handleClose"
      :modal-append-to-body="false" :append-to-body="true" class="dialog">
      <div class="cont-box">
        <PlanList :planData="planData"></PlanList>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subCont('curData02')">确认（计划）</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import qs from 'qs'
  import PlanList from '../../components/PlanList.vue'

  export default {
    name: 'ServePlan',
    components: {
      PlanList
    },
    data() {
      return {
        loading: true,
        dialogVisible: false,
        drawer: false,
        direction: 'ttb',
        activeNames: ['0'],
        formInline: {
          company: 'DQ',
          xmdddocno: '',
          xmddseq: '',
          xmddseq1: '',
          xmdaua001: '',
          pjabl003: '',
          xmddua017: ''
        },
        companies: [{
            value: 'DQ',
            lable: '电气'
          },
          {
            value: 'ZX',
            lable: '正信'
          },
          {
            value: 'GF',
            lable: '股份'
          }
        ],
        tableData: [],
        planData: [],
        page: 1,
        rows: 10,
        total: null
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList(e) {
        this.loading = true
        if (e === 'search') {
          this.page = 1
        }
        this.$axios.post('/three-week/findPlanList', {
          current: this.page,
          size: this.rows,
          ...this.formInline
        }, {
          timeout: 15000
        }).then(res => {
          this.loading = false
          this.tableData = res.data.data.records
          this.total = res.data.data.total
        }).catch(err => {
          this.loading = false
          // this.$message.error('加载失败，请稍候再试。')
        })
      },
      deleteRow(index, rows) {
        rows.splice(index, 1);
      },
      handleCurrentChange(val) {
        this.page = val
        this.getList()
      },
      changeRow(index, data) {
        this.dialogVisible = true
        this.$axios.post('/three-week/findWorkOrder', {
          xmdddocno: data[index].xmdddocno
        }).then(res => {
          this.planData = res.data.data
        }) 
      },
      onSearch(e) {
        this.getList(e)
      },
      subCont(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.post('/three-week/save', this.curData01).then(res => {
              this.dialogVisible = false
              this.$message({
                message: '添加成功',
                type: 'success'
              })
            })
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      handleClose() {
        // this.resetForm('curData02')
        // this.resetForm('curData02')
        // this.curData02.threeWeekId = ''
      }
    }
  }
</script>

<style scoped>
  .demo-form-inline {
    display: flex;
  }

  .el-table {
    box-sizing: border-box;
  }

  .drawer-cont {
    box-sizing: border-box;
    padding: 0 20px;
  }

  .el-tag {
    /* cursor: pointer; */
  }

  .el-table>>>.cell .el-button {
    padding: 0;
  }

  /*  .el-dialog__wrapper {
    height: 100%;
  }
  .el-dialog__wrapper >>> .el-dialog {
    height: 80%;
    overflow-y: auto;
  } */
  .cont-box {
    padding: 0 10px;
    height: 100%;
    overflow-y: auto;
  }

  .list-cont {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1em;
    padding: 14px 0;
  }

  .list-cont01 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1em;
    padding: 14px 0;
  }

  .list-cont li {
    box-sizing: border-box;
    width: 25%;
    display: flex;
    padding: 12px 6px;
    align-items: center;
  }

  .list-cont01>div {
    width: 25%;
    display: flex;
    padding: 6px 0;
    align-items: center;
    margin-bottom: 0;
  }

  .title {
    line-height: 34px;
    background: #f9f9f9;
    text-indent: 1em;
    color: #999999;
  }

  .list-cont li>label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__content {
    flex: 1;
    margin: 0 1em 0 .5em;
  }

  .list-cont li>p {
    flex: 1;
    color: #323232;
    margin: 0 10px;
    min-height: 20px;
    border-bottom: 1px solid #dedede;
  }

  .list-cont li>.el-input,
  .list-cont li>.el-select,
  .list-cont li>.el-textarea {
    flex: 1;
    margin: 0 1em 0 .5em;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .el-select {
    width: 100%;
  }

  .el-table>>>.el-table__fixed,
  .el-table>>>.el-table__fixed-right {
    bottom: 0 !important;
  }
</style>
